<template>
  <div>
    <ui-component-drawer
      v-if="showSideDrawer"
      :drawerTitle="drawerTitle"
      :showDrawer="showSideDrawer"
      :onClickClose="hideDrawer"
      position="right"
    >
      <template v-slot:content>
        <customer-summary
          v-if="showCustomerSummary"
          :customerId="cart.ProfileId"
          :locationId="cart.LocationId"
        ></customer-summary>

        <company-summary
          v-if="showCompanySummary"
          :companyId="cart.CompanyId"
          :locationId="cart.LocationId"
        ></company-summary>
      </template>
    </ui-component-drawer>

    <edit-cart-data
      v-if="showEditBasicData"
      :showModal="showEditBasicData"
      :onClickCancel="hideModal"
      @onClickCancel="hideModal"
    />

    <ui-section-header
      :showEdit="true"
      :editFunction="setShowEditCartData"
      :editLabel="$t('Components.Carts.CartDetail.Button_Edit')"
    >
      <template v-slot:title>Data</template>
    </ui-section-header>

    <table class="table is-fullwidth is-borderless is-narrow">
      <tbody>
        <tr>
          <td>{{ $t('Components.Carts.CartDetail.Table_Type') }}</td>
          <td>
            {{ getCartTypeName(cart.CartType) }}
          </td>
        </tr>
        <tr>
          <td>{{ $t('Components.Carts.CartDetail.Table_Date') }}</td>
          <td>
            {{ cart.StartDate | parseIsoDateStringToDate | dateFormat }}
          </td>
        </tr>
        <tr>
          <td>{{ $t('Components.Carts.CartDetail.Table_Time') }}</td>
          <td>
            {{ cart.StartMinutes | minutesToTime }} -
            {{ cart.EndMinutes | minutesToTime }}
          </td>
        </tr>
        <tr>
          <td>{{ $t('Components.Carts.CartDetail.Table_Name') }}</td>
          <td>{{ cart.Name }}</td>
        </tr>
        <tr>
          <td>
            {{ $t('Components.Carts.CartDetail.Table_Company') }}
          </td>
          <td>
            <span v-if="cart.CompanyId > 0">
              <a @click="setShowCompanySummary(cart.CompanyId)">{{
                cart.CompanyName
              }}</a>
            </span>
          </td>
        </tr>
        <tr>
          <td>
            {{ $t('Components.Carts.CartDetail.Table_Booker') }}
          </td>
          <td>{{ cart.ProfileName }}</td>
        </tr>
        <tr>
          <td>
            {{ $t('Components.Carts.CartDetail.Table_Voucher') }}
          </td>
          <td>
            <check-voucher
              :cart="cart"
              @cartVoucherApplied="cartVoucherApplied"
            ></check-voucher>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

const CheckVoucher = () => import('@/components/Vouchers/CheckVoucher')
const CompanySummary = () => import('@/components/Companies/CompanySummary')
const CustomerSummary = () => import('@/components/Customers/CustomerSummary')
const EditCartData = () => import('@/components/Carts/ModalEditCartData')

export default {
  components: {
    'check-voucher': CheckVoucher,
    'company-summary': CompanySummary,
    'customer-summary': CustomerSummary,
    'edit-cart-data': EditCartData,
  },

  data() {
    return {
      cartTypes: [],
      drawerTitle: '',
      showCompanySummary: false,
      showCustomerSummary: false,
      showEditBasicData: false,
      showSideDrawer: false,
    }
  },

  computed: {
    ...mapState('cartStore', ['cart']),
  },

  created() {
    this.cartTypes.push({ Id: 0, Type: 'RegularReservation', Name: 'Regular' })
    this.cartTypes.push({
      Id: 1,
      Type: 'DoubleBooking',
      Name: 'Double booking',
    })
    this.cartTypes.push({ Id: 2, Type: 'Proposal', Name: 'Proposal' })
  },

  methods: {
    ...mapMutations('cartStore', ['setCart']),

    getCartTypeName(type) {
      let typeIndex = this.cartTypes.findIndex((t) => t.Type === type)
      if (typeIndex > -1) {
        return this.cartTypes[typeIndex].Name
      }
      return ''
    },

    cartVoucherApplied(cart) {
      this.setCart(cart)
    },

    setShowEditCartData() {
      this.showEditBasicData = true
    },

    hideModal() {
      this.showEditBasicData = false
    },

    setShowCompanySummary(companId) {
      this.itemId = companId
      this.drawerTitle = 'Company detail'
      this.showSideDrawer = true
      this.showCompanySummary = true
    },

    setShowCustomerSummary(customerId) {
      this.itemId = customerId
      this.drawerTitle = 'Customer detail'
      this.showSideDrawer = true
      this.showCustomerSummary = true
    },

    hideDrawer() {
      this.itemId = 0
      this.drawerTitle = ''
      this.showCompanySummary = false
      this.showCustomerSummary = false
      this.showSideDrawer = false
    },
  },
}
</script>

<style></style>
